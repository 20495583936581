import React, { useState,Fragment, useEffect }  from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import BlogPost from 'common/src/components/BlogPost';
import firestore from "../../../firestore";
import  firebase from "firebase/app";
import "firebase/firestore";
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  LinkArea,
  Text,
  PostArea,
} from './blogSection.style';

const BlogSection = () => {
  
        const [state, setState] = useState({
          des:[]
        });
      useEffect(() => {
        // if (!firestore) return;
        async function fetchData(){
          const response = await firebase.firestore().collection("BlogsDetails").where('ArticleCategory','==','Stories').orderBy("timestamp","desc")
          .onSnapshot(function(querySnapshot) {
              var cities = [];
              querySnapshot.forEach(function(doc) {
                var obj = doc.data()
                obj.id = doc.id
                  cities.push(obj);
                  setState({
                    ...state,
                    des: cities,
                  });
              });
           
          });
          
        }
        fetchData();
       },[]);
      //  useEffect(() => {
      //   async function fetchData() {
      //     // You can await here
      //     const response = await MyAPI.getData(someId);
      //     // ...
      //   }
      //   fetchData();
      // }, [someId]);
      var responses  = state.des;
   
  return (
    <SectionWrapper id="blog">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content="All Stories" />
            {/* <Text>
              People around the world are raising money for what they are
              passionate about. Get The Latest PayBear Updates
              <a href="#1" className="link">
                Join us on Community
              </a>
            </Text> */}
          </TitleArea>
         
        </SectionHeader>
        <PostArea>
          {state.des.map(item => (
            <BlogPost
              key={`blog__post-key${item.id}`}
              thumbUrl={item.BannerImg? item.BannerImg :""}
              title={item.Title}
              excerpt={`${item.ArticleCopy.substring(0,200)}...`}
              link={
                <Fragment>
                <Link className="learn__more-btn" to={`/blogdetails/${item.id}`}
                state={{  blogid: item.id }}>
                  <span className="hyphen"></span>
                  <span className="btn_text">Read more</span>
                </Link>
                </Fragment>
              }
            />
          ))}
        </PostArea>
      </Container>
    </SectionWrapper>
  );
};

export default BlogSection;
